// extracted by mini-css-extract-plugin
export var additionalNotes = "UserCloudIaasServerProject_Details_RequiredProducts__additionalNotes__yWlxd";
export var checkboxes = "UserCloudIaasServerProject_Details_RequiredProducts__checkboxes__HGJAS";
export var checkboxesContainer = "UserCloudIaasServerProject_Details_RequiredProducts__checkboxesContainer__cYLxE";
export var column = "UserCloudIaasServerProject_Details_RequiredProducts__column__QqOLv";
export var description = "UserCloudIaasServerProject_Details_RequiredProducts__description__G7Fyg";
export var descriptionHeading = "UserCloudIaasServerProject_Details_RequiredProducts__descriptionHeading__UIGZL";
export var lightBackground = "UserCloudIaasServerProject_Details_RequiredProducts__lightBackground__xqP11";
export var notesContainer = "UserCloudIaasServerProject_Details_RequiredProducts__notesContainer__oEU8S";
export var panel = "UserCloudIaasServerProject_Details_RequiredProducts__panel__eZ8Ek";
export var panelBody = "UserCloudIaasServerProject_Details_RequiredProducts__panelBody__a7udv";
export var panelHeading = "UserCloudIaasServerProject_Details_RequiredProducts__panelHeading__zqfwz";
export var productName = "UserCloudIaasServerProject_Details_RequiredProducts__productName__FbS94";
export var productRow = "UserCloudIaasServerProject_Details_RequiredProducts__productRow__P0jHB";
export var productServiceLabel = "UserCloudIaasServerProject_Details_RequiredProducts__productServiceLabel__uYA05";
export var productServiceValue = "UserCloudIaasServerProject_Details_RequiredProducts__productServiceValue__ea2B7";
export var productsServiceWrapper = "UserCloudIaasServerProject_Details_RequiredProducts__productsServiceWrapper__hLHGa";
export var propertiesContainer = "UserCloudIaasServerProject_Details_RequiredProducts__propertiesContainer__aG8kj";
export var propertyLeft = "UserCloudIaasServerProject_Details_RequiredProducts__propertyLeft__gKpFO";
export var propertyRight = "UserCloudIaasServerProject_Details_RequiredProducts__propertyRight__fqcrx";
export var row = "UserCloudIaasServerProject_Details_RequiredProducts__row__sUGXv";
export var stayShaded = "UserCloudIaasServerProject_Details_RequiredProducts__stayShaded__XNpMJ";
export var switchLight = "UserCloudIaasServerProject_Details_RequiredProducts__switchLight__Q8rO9";
export var switchShaded = "UserCloudIaasServerProject_Details_RequiredProducts__switchShaded__gIGB7";
export var tickmarkIcon = "UserCloudIaasServerProject_Details_RequiredProducts__tickmarkIcon__I28PX";
export var withColon = "UserCloudIaasServerProject_Details_RequiredProducts__withColon__qGXHh";